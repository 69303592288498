import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Typography, useMediaQuery } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { colors } from "../../assets/colors/index";
import * as projectImages from "../../assets/index";
import { Link, useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { navigation } from "../../Navigation";
import { Grid } from "@mui/material";
import { Tiktok } from "../../assets/index";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import AnchorTemporaryDrawer from "../../components/drawer/Drawer";

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();

  const icon = (
    <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
      <svg>
        <Box
          component="polygon"
          points="0,100 50,00, 100,100"
          sx={{
            fill: (theme) => theme.palette.common.white,
            stroke: (theme) => theme.palette.divider,
            strokeWidth: 1,
          }}
        />
      </svg>
    </Paper>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        py: 2,
      }}
    >
      {/* <Container> */}
      <Toolbar disableGutters>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item md={3}>
            <Box
              component={Link}
              to={"/"}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box component={"img"} src={projectImages.images.logo} />
            </Box>
          </Grid>
          <Grid item md={9} display={{ md: "block", sm: "none", xs: "none" }}>
            <Grid container justifyContent={"flex-end"} alignItems={"center"} gap={"20px"}>
              <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "15px",
                  }}
                >
                  {navigation.map((item, index) => (
                    <Button key={index} onClick={handleCloseNavMenu}>
                      <Box
                        className="nav-link"
                        component={Link}
                        to={item.path}
                        sx={{
                          fontSize: "16px",
                          textDecoration: "none",
                          color: colors.textColor,
                          fontWeight: "bold",
                          transition: "0.2s",
                          "&:hover": {
                            color: `${colors.primaryColor} !important`,
                          },
                        }}
                      >
                        {item.name}
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Grid>
              <Grid item md={1.5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => navigate("/signup")}
                    variant="contained"
                    sx={{
                      height: "45px",
                      p: "12px 24px",
                      backgroundColor: colors.primaryColor,
                      boxShadow: "none",
                      fontWeight: "bold",
                      fontSize: "15px",
                      borderRadius: "7px",
                      transition: "0.3s",
                      border: `2px solid ${colors.primaryColor}`,
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#000000",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Sign Up
                  </Button>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Grid container justifyContent={"flex-start"} alignItems={"center"} gap={"5px"}>
                  {/* Facebook */}
                  <Grid item lg={2}>
                    <Box
                      component={"a"}
                      href="https://www.facebook.com/OfficialAdStreet"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Connet us on Facebook"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <FacebookIcon
                        sx={{
                          color: "black",
                          fontSize: "28px",
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* Instagram */}
                  <Grid item lg={2}>
                    <Box
                      component={"a"}
                      href="https://www.instagram.com/adstreetofficial/"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Connet us on Instagram"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <InstagramIcon
                        sx={{
                          color: "black",
                          fontSize: "28px",
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* Twitter */}
                  <Grid item lg={2}>
                    <Box
                      component={"a"}
                      href="https://twitter.com/theAdStreet"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Connet us on Twitter"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <TwitterIcon
                        sx={{
                          color: "black",
                          fontSize: "28px",
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* Tiktok */}
                  <Grid item lg={2}>
                    <Box
                      component={"a"}
                      href="https://www.tiktok.com/@adstreetofficial"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Connect us on Tiktok"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        ":hover": {
                          fill: colors.primaryColor,
                        },
                      }}
                    >
                      <Tiktok />
                    </Box>
                  </Grid>
                  {/* LinkedIn */}
                  <Grid item lg={2}>
                    <Box
                      component={"a"}
                      href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQH9nFTbWQutDQAAAYkrniXgpFlQu5ymyg9Bznjxt5RPnOlhOtq3kc-VjGH9UeM23zRClsoMHLApJXZ-oDvCKKl1rGzBbb2GFFQZ-Vz9SA42L_-jjPsUBk1HgTdoh5GX_TWlXds=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F96397314"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Connet us on LinkedIn"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <LinkedInIcon
                        sx={{
                          color: "black",
                          fontSize: "28px",
                          "&:hover": {
                            color: colors.primaryColor,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
          }}
        >
          <AnchorTemporaryDrawer navigation={navigation} anchorElNav={anchorElNav} handleCloseNavMenu={handleCloseNavMenu} />
          {/* <Menu
            className="menu-animation"
            anchorEl={anchorElNav}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            PaperProps={{
              sx: {
                minWidth: "100%",
                left: "0 !important",
                top: "75px !important",
                borderRadius: 0,
                boxShadow: "none",
                background: "black", // Set your desired background color
              },
            }}
          >
            {navigation.map((page, ind) => (
              <MenuItem
                className="slide"
                key={ind}
                onClick={() => {
                  navigate(page.path);
                  handleCloseNavMenu();
                }}
                sx={{
                  p: "12px 16px",
                  transition: `all .5s ease`,
                  ":hover": {
                    backgroundColor: "#3f51b5", // Set your hover background color
                  },
                  transition: "all 0.5s ease",
                }}
              >
                <Typography
                  sx={{
                    color: colors.white,
                    transform: `translateY(0)`,
                  }}
                >
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu> */}
        </Box>
      </Toolbar>
      {/* </Container> */}
    </AppBar>
  );
}
export default Header;
