import AdmeetRoutes from "./Admeet.routes";
import { post } from "../index";

const AdmeetService = {
  register: async (obj) => {
    const result = await post(AdmeetRoutes.register, obj);
    return result;
  },
};

export default AdmeetService;
