import { getState } from "./State.routes";
import { get } from "../index";

const states = {
  gettingState: async (id) => {
    console.log("Submit id =>", id);
    const result = await get(getState.gettingState + `/${id}`);
    console.log(result);
    return result;
  },
};

export default states;
