import { loggingIn } from "./Login.routes";
import { post } from "../index";

const LoginService = {
  logIn: async (obj) => {
    const result = await post(loggingIn.login, obj);
    return result;
  },
};

export default LoginService;
