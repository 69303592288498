import { get } from "../index";
import { countries } from "./Countries.routes";

const gettingCountries = {
  getCountry: async () => {
    const result = await get(countries.getCountries);
    return result;
  },
};

export default gettingCountries;
