export const images = {
  // Images use in home page
  logo: require("./images/logo.png"),
  homePageDropDownLogo: require("./images/home-page-arrow-down.png"),
  adstreetSign: require("./images/home-page-adstreet-adventure.png"),
  adbazaarMobilePhone: require("./images/home-page-adstreet-adbazaar.png"),
  adbazaarBg: require("./images/home-page-adbazaar-bg.jpg"),
  adbazaarTextImg: require("./images/home-page-adbazaar-text-img.png"),
  adleaksMobilePhone: require("./images/home-page-adstreet-adleaks.png"),
  adleaksBg: require("./images/home-page-adleaks-bg.jpg"),
  adleaksTextImg: require("./images/home-page-adleaks-text-img.png"),
  adbooksMobilePhone: require("./images/home-page-adstreet-adbook.png"),
  adbooksBg: require("./images/home-page-adbooks-bg.jpg"),
  adbooksTextImg: require("./images/home-page-adbooks-text-img.png"),
  admeetMobilePhone: require("./images/home-page-adstreet-admeet.png"),
  admeetBg: require("./images/home-page-admeets-bg.jpg"),
  admeetTextImg: require("./images/home-page-admeet-text-img.png"),
  adguruMobilePhone: require("./images/home-page-adstreet-adguru.png"),
  adguruBg: require("./images/home-page-adguru-bg.jpg"),
  adguruTextImg: require("./images/home-page-adguru-text-img.png"),
  adproServicesMobilePhone: require("./images/home-page-adpro.png"),
  proServicesBg: require("./images/home-page-adpro-services-bg.jpg"),
  adproTextImg: require("./images/home-page-adpro-services-text-img.png"),
  adreadMobilePhone: require("./images/home-page-adread.png"),
  adreadBg: require("./images/home-page-adread-bg.jpg"),
  adreadTextImg: require("./images/home-page-adread-text-img.png"),
  shell: require("./images/banner-iphone-shell.png"),
  screen1: require("./images/banner-iphone-screen-v1.png"),
  screen2: require("./images/banner-iphone-screen2.png"),
  screen3: require("./images/banner-iphone-screen3.png"),
  adbazaar_ph: require("./images/red-iphone-res.png"),
  adleaks_ph: require("./images/orange-iphone-res.png"),
  adbooks_ph: require("./images/yellow-iphone-res.png"),
  admeet_ph: require("./images/blue-iphone-res.png"),
  adguru_ph: require("./images/grey-iphone-res.png"),
  adpro_ph: require("./images/purple-iphone-res.png"),
  adread_ph: require("./images/maroon-iphone-res.png"),

  // Image use in multipe pages
  // signUpNow: require("./images/home-page-signup-now.png"),
  multiplePagesBg: require("./images/bg-img.png"),

  // Images use in about page
  meetTheFounder: require("./images/about-page-founder-img.png"),

  //Images use in adleaks page
  adleaksPageBg: require("./images/adleaks-bg.jpg"),
  cardOne: require("./images/adleaks-first-card.jpg"),
  cardTwo: require("./images/adleaks-second-card-img.jpg"),
  cardThree: require("./images/adleaks-third-card-img.jpg"),
  cardFour: require("./images/adleaks-fourth-card-img.jpg"),
  cardFive: require("./images/adleaks-fifth-card-img.jpg"),
  cardSix: require("./images/adleaks-sixth-card-img.jpg"),
  cardSeven: require("./images/adleaks-seven-card-img.jpg"),
  cardEight: require("./images/adleaks-eight-card-img.jpg"),
  cardNine: require("./images/adleaks-ninth-card-img.jpg"),
  cardTen: require("./images/adleaks-ten-card-img.jpg"),
  cardEleven: require("./images/adleaks-eleven-card-img.jpg"),
  cardTwelve: require("./images/adleaks-twelve-card-img.jpg"),
  cardThirteen: require("./images/adleaks-thirteenth-card-img.jpg"),
  cardFourteen: require("./images/adleaks-fourteenth-card-img.jpg"),
  cardFifteen: require("./images/adleaks-fifteen-card-img.jpg"),
  cardSixteen: require("./images/adleaks-sixteen-card-img.jpeg"),
  cardSeventeen: require("./images/adleaks-seventeen-card-img.jpg"),
  cardEighteen: require("./images/adleaks-eighteenth-card-img.png"),
  cardNinteen: require("./images/adleaks-ninteenth-card-img.jpg"),
  cardTwenty: require("./images/adleaks-twenty-card-img.jpg"),
  cardTwentyOne: require("./images/adleaks-twenty-one-card-img.jpg"),
  cardTwentyTwo: require("./images/adleaks-twenty-two-card-img.jpg"),
  cardTwentyThree: require("./images/adleaks-twenty-three-card-img.jpg"),
  cardTwentyFour: require("./images/adleaks-twenty-four-card-img.jpg"),

  // Images use in adpro page
  adProBgImg: require("./images/adpro-bg-img.jpg"),
  adProLogo: require("./images/adpro-logo.png"),

  // Images use in admeet page
  adMeetBgImg: require("./images/admeet-bg-img.jpg"),
  adMeetLogo: require("./images/admeet-logo.png"),

  // Images use in footer page
  footerSectionOneImg: require("./images/footer-section-one-logo.png"),
  // Google logo image

  googleLogo: require("./images/googleLogo.png"),
  dragons: require("./images/dragon.jpg"),
  dragonEvpt: require("./images/dargon2.jpg"),
  advision: require("./images/advision.jpeg"),
};

export const Tiktok = () => {
  return (
    <svg viewBox="0 0 448 512" height="24px" width="24px">
      <path d="M448 209.91a210.06 210.06 0 01-122.77-39.25v178.72A162.55 162.55 0 11185 188.31v89.89a74.62 74.62 0 1052.23 71.18V0h88a121.18 121.18 0 001.86 22.17A122.18 122.18 0 00381 102.39a121.43 121.43 0 0067 20.14z" />
    </svg>
  );
};
