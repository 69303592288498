import AboutPage from "../pages/about/AboutPage";
import Adleaks from "../pages/adleaks/Adleaks";
import Adpro from "../pages/adProPage/AdPro";
import AdmeetReg from "../pages/admeetReg/AdmeetReg";
import Home from "../pages/homepage/Home";
import Signup from "../pages/auth/Signup";
import BlogDetails from "../pages/adleaksBlogDetails/BlogDetails";
import PrivacyPolicy from "../pages/privacyPolicy/Privacy";
import Terms from "../pages/termsOfUse/Terms";
// import Dragons from "../pages/dragonsBlog/Dragons";
import Login from "../pages/login/Login";
import AdmeetBlogs from "../pages/admeetBlogs/AdmeetBlogs";
import AdmeetBlogDetails from "../pages/admeetBlogDetail/AdmeetBlogDetail";
import Advision from "../pages/advision";
import Dragons from "../pages/dragons";
import EventPartner from "../pages/eventPartners";

const route = [
  // {
  //   path: "/",
  //   component: <Home/>,
  // },
  {
    path: "/about",
    component: <AboutPage />,
  },
  {
    path: "/adleaks",
    component: <Adleaks />,
  },
  {
    path: "/adpro",
    component: <Adpro />,
  },
  {
    path: "/admeet",
    component: <AdmeetBlogs />,
  },
  {
    path: "/admeet-blog/:id",
    component: <AdmeetBlogDetails />,
  },
  {
    path: "/admeet-registeration",
    component: <AdmeetReg />,
  },
  {
    path: "/signup",
    component: <Signup />,
  },
  {
    path: "/adleaks-blog/:id",
    component: <BlogDetails />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/terms-of-use",
    component: <Terms />,
  },
  {
    // path: "/dragons",
    // component: <Dragons />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/advision",
    component: <Advision />,
  },
  {
    path: "/dragons-of-pakistan",
    component: <Dragons />,
  },
  {
    path: "/dragons",
    component: <EventPartner />,
  },
];

export default route;
