import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./styles.css";

// import required modules
import { Autoplay, FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";

import { Box, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Banner({ images, baseUrl }) {
  console.log("Getting baseURL =>", baseUrl);
  console.log("Getting images from prop =>", images);
  const navigate = useNavigate();
  return (
    <Swiper
      slidesPerView={1}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      navigation={true}
      modules={[Pagination, FreeMode, Navigation, Thumbs]}
      className="mySwiper"
    >
      {images.map((item, index) => (
        <SwiperSlide key={index}>
          <CardMedia
            component={"img"}
            src={baseUrl + item?.imageUrl}
            alt={"image"}
            onClick={() => {
              if (index == 0) {
                navigate("/dragons-of-pakistan")
              } else if (index == 1) {
                navigate("/advision")
              } else if (index == 2) {
                navigate("/dragons")
              }
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Banner;
