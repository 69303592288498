import axios from "axios";
const server = "https://adstreet.mangotech-api.com/api";
export const baseUrl = "https://adstreet.mangotech-api.com";

const instance = axios.create({
  baseURL: server,
});

const token = localStorage.getItem("token");

instance.interceptors.request.use((request) => {
  request.headers = {
    Accept: "application/json, text/plain, */*",
    Authorization: `${token}`,
  };
  return request;
});

instance.interceptors.response.use(
  (response) => {
    if (response) {
      return response;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
