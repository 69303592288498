import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { colors } from "../../assets/colors/index";

export default function BasicChips({ content, sx, backgroundColor }) {
  return (
    <Stack direction="row" spacing={1}>
      <Chip
        label={content}
        sx={{
          ...sx,
          borderRadius: "5px",
          // width: "100",
          backgroundColor: "transparent",
        }}
      />
    </Stack>
  );
}
