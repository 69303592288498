import AuthRoutes from "./Auth.routes";
import { post } from "../index";

const AuthService = {
  signUp: async (obj) => {
    const result = await post(AuthRoutes.signUp, obj);
    return result;
  },
};

export default AuthService;
