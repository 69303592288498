import React, { Fragment, useState, useEffect } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { images } from "../../assets";
import { colors } from "../../assets/colors";
import InputField from "../../components/InputField";
import ServicesService from "../../api/services/ServicesService";
import IndustriesService from "../../api/industries/IndustriesService";
import { useForm } from "react-hook-form";
import AuthService from "../../api/auth/AuthService";
import SelectField from "../../components/SelectField";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { SuccessToaster } from "../../components/toaster/Toaster";
import gettingCountries from "../../api/countries/CountriesService";
import getCities from "../../api/cities/CitiesService";
import states from "../../api/state/Stateservices";
import { cities } from "../../api/cities/Cities.routes";
import OtpInput from "react-otp-input";
import { MuiOtpInput } from "mui-one-time-password-input";
import BasicModal from "../../components/modal/Modal";

function Signup() {
  const returnToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const providerQuestions = [
    {
      que: "Are you interested in AdMeet by AdStreet?",
      opt1: "Yes",
      opt2: "No",
    },
    {
      que: "Are you interested in AdGuru training programs/sessions?",
      opt1: "Yes",
      opt2: "No",
    },
    {
      que: "Are you interested to receive briefs and work from clients online?",
      opt1: "Yes",
      opt2: "No",
    },
    {
      que: "Are you interested to get contacted by AdStreet for assistance and offers?",
      opt1: "Yes",
      opt2: "No",
    },
  ];
  const seekerQuestions = [
    {
      que: "Are you interested in AdStreet AdGuru training programs/sessions?",
      opt1: "Yes",
      opt2: "No",
    },
    {
      que: "Are you interested to receive value offers/deals from service providers?",
      opt1: "Yes",
      opt2: "No",
    },
    {
      que: "Are you interested to get contacted by AdStreet for assistance and offers?",
      opt1: "Yes",
      opt2: "No",
    },
  ];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [selected, setSelected] = useState("Member");
  const [services, setServices] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [country, setCountry] = useState([]);
  const [province, setProvince] = useState([]);
  console.log("🚀 ~ Signup ~ province:", province);
  // const [state, setState] = useState([]);
  // console.log("🚀 ~ Signup ~ state:", state);
  const [city, setCity] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [otp, setOtp] = useState("");
  const [title, setTitle] = useState("Become a Member - ADSTREET");

  const getAllServices = async () => {
    try {
      const result = await ServicesService.getAllService();
      if (result.responseCode === 200) {
        setServices(result?.data?.service);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllIndustries = async () => {
    try {
      const result = await IndustriesService.getAllIndustries();
      if (result.responseCode === 200) {
        setIndustries(result?.data?.industry);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getALlCountries = async () => {
    try {
      const result = await gettingCountries.getCountry();
      if (result.responseCode === 200) {
        setCountry(result?.data?.countries);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getALlState = async (selectedCountry) => {
    console.log("Called", selectedCountry);
    try {
      const result = await states.gettingState(selectedCountry);
      if (result.responseCode === 200) {
        console.log(result, "sdabjksajksda");
        setProvince(result?.data?.states);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllCities = async (selectedState) => {
    try {
      const result = await getCities.gettingCities(selectedState);
      if (result.responseCode === 200) {
        setCity(result?.data?.cities);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const submitForm = async (formData) => {
    console.log(selectedCountry?.name);
    const obj = {
      name: formData.fullName,
      phoneNumber: formData.phone,
      email: formData.email,
      password: formData.password,
      roles: selected === "Member" ? "Individual" : selected,
      country: selectedCountry?.name,
      city: selectedCity?.name,
      state: selectedState?.name,
      additional: {
        company_name: formData.companyName,
        years_experience: formData.experience,
        industry: formData.memberIndustry,
        services: "",
        currentRole: formData?.currentRole,
        area_expertise: "",
        s_link: "",
        w_link: "",
        p_link: "",
      },
    };

    try {
      const result = await AuthService.signUp(obj);
      if (result.responseCode === 200) {
        // console.log(result);
        SuccessToaster("SignUp Successfull");
        reset();

        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const callApi = async (e) => {
    const ObjId = e.target.value;
    console.log("ObjId =>", ObjId);
    const selectedCountryObj = country?.find((item) => item._id == ObjId);
    console.log("selectedCountryObj =>", selectedCountryObj?.country_id);
    getALlState(selectedCountryObj?.country_id);
  };

  const callApiForCities = async (e) => {
    const ObjId = e.target.value;
    // console.log("e =>", e.target.value);
    const selectedCityObj = province?.find((item) => item._id == ObjId);
    getAllCities(selectedCityObj?.state_id);
  };

  const handleSelectedCity = (e) => {
    console.log("e =>", e);
  };

  const handleOtp = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    getAllServices();
    getAllIndustries();
    getALlCountries();
  }, []);

  return (
    <Box
      sx={{
        background: `url(${images.multiplePagesBg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>

      {/* <MuiOtpInput value={otp} onChange={handleOtp} /> */}
      <Grid container gap={"20px"} p={"20px 20px 60px 20px"}>
        <Grid item md={9} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "37px",
              color: colors.white,
            }}
          >
            TAKE THE FIRST STEP OF BECOMING A PIONEER
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              color: colors.white,
            }}
          >
            Provide us your information so that we can add you to our wait list. You will receive notification on priority basis when our app goes live!
          </Typography>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box component={"form"} onSubmit={handleSubmit(submitForm)}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <Grid container>
                  <Grid item md={6} sm={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={3} sm={4} xs={12}>
                        <Button
                          fullWidth
                          sx={{
                            padding: "10px 0px",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: colors.white,
                            backgroundColor: selected == "Member" ? "transparent" : colors.primaryColor,
                            border: `2px solid ${colors.primaryColor}`,
                            borderRadius: "10px",
                          }}
                          onClick={() => setSelected("Member")}
                        >
                          Member
                        </Button>
                      </Grid>
                      <Grid item md={4.6} sm={4} xs={12}>
                        <Button
                          fullWidth
                          sx={{
                            padding: "10px 0px",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: colors.white,
                            backgroundColor: selected == "Provider" ? "transparent" : colors.primaryColor,
                            border: `2px solid ${colors.primaryColor}`,
                            borderRadius: "10px",
                          }}
                          onClick={() => setSelected("Provider")}
                        >
                          Service Provider
                        </Button>
                      </Grid>
                      <Grid item md={4.4} sm={4} xs={12}>
                        <Button
                          fullWidth
                          sx={{
                            padding: "10px 0px",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: colors.white,
                            backgroundColor: selected == "Seeker" ? "transparent" : colors.primaryColor,
                            border: `2px solid ${colors.primaryColor}`,
                            borderRadius: "10px",
                          }}
                          onClick={() => setSelected("Seeker")}
                        >
                          Service Seeker
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Grid container>
                  <Grid item md={9} sm={12} xs={12}>
                    {selected == "Seeker" ? (
                      <Typography sx={{ color: colors.white }}>For members who are seeking professional services in their specific industry for any relevant project requirements.</Typography>
                    ) : selected == "Provider" ? (
                      <Typography sx={{ color: colors.white }}>For members who provide services in their respective fields and are interested in working with clients and brands.</Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* Member start */}

              {selected == "Member" && (
                <Fragment>
                  {/* Member full name */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Tell Us Your Full Name (Required)"}
                              register={register("fullName", {
                                required: "Please Tell Us Your Full Name",
                              })}
                              error={errors?.fullName && true}
                              helperText={errors?.fullName?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member email */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Email Address (Required)"}
                              register={register("email", {
                                required: "Please Tell Us Your Email Address",
                              })}
                              error={errors?.email && true}
                              helperText={errors?.email?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member password */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Password (Required)"}
                              register={register("password", {
                                required: "Please Tell Us Your Password",
                              })}
                              error={errors?.password && true}
                              helperText={errors?.password?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member phone number */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Phone Number (Required)"}
                              register={register("phone", {
                                required: "Please Tell Us Your Phone Number",
                              })}
                              error={errors?.phone && true}
                              helperText={errors?.phone?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member company name */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Your Company Name (Optional)"} register={register("companyName")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member country */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <SelectField
                              register={register("country", { onChange: (e) => callApi(e) })}
                              selectType={"country"}
                              data={country}
                              value={selectedCountry !== null ? selectedCountry?._id : ""}
                              setValue={setSelectedCountry}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member state */}
                  {selectedCountry !== null && (
                    <Grid item md={12} sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <SelectField
                                disabled={selectedCountry ? true : false}
                                register={register("state", { onChange: (e) => callApiForCities(e) })}
                                selectType={"state"}
                                data={province}
                                value={selectedState !== null ? selectedState?._id : ""}
                                setValue={setSelectedState}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Member city */}
                  {selectedState !== null && (
                    <Grid item md={12} sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <SelectField
                                // disabled={selectedCountry ? true : false}
                                register={register("city")}
                                selectType={"city"}
                                data={city}
                                value={selectedCity !== null ? selectedCity?._id : ""}
                                setValue={setSelectedCity}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Member current role */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Your Current Role (Optional)"} register={register("currentRole")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member industry */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <SelectField selectType={selected} data={industries} value={selectedIndustry !== null ? selectedIndustry?._id : ""} setValue={setSelectedIndustry} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member experience */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Your Working Experience (Optional)"} register={register("experience")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member acceptance */}
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: colors.white,
                                    "&.Mui-checked": {
                                      color: colors.white,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    color: colors.white,
                                  }}
                                >
                                  I Accept Terms of Use and Privacy Policy
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )}

              {/* Member end */}

              {/* Service provider start */}

              {selected == "Provider" && (
                <Fragment>
                  {/* Provider name */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Tell Us Your Full Name (Required)"}
                              register={register("fullName", {
                                required: "Please Tell Us Your Full Name",
                              })}
                              error={errors?.fullName && true}
                              helperText={errors?.fullName?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider email */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Email Address (Required)"}
                              register={register("email", {
                                required: "Please Tell Us Your Email Address",
                              })}
                              error={errors?.email && true}
                              helperText={errors?.email?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider password */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Password (Required)"}
                              register={register("password", {
                                required: "Please Tell Us Your Password",
                              })}
                              error={errors?.password && true}
                              helperText={errors?.password?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider phone number */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Phone Number (Required)"}
                              register={register("phone", {
                                required: "Please Tell Us Your Phone Number",
                              })}
                              error={errors?.phone && true}
                              helperText={errors?.phone?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider services */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <SelectField selectType={selected} data={services} value={selectedService !== null ? selectedService?._id : ""} setValue={setSelectedService} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Member country */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <SelectField
                              register={register("country", { onChange: (e) => callApi(e) })}
                              selectType={"country"}
                              data={country}
                              value={selectedCountry !== null ? selectedCountry?._id : ""}
                              setValue={setSelectedCountry}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider state */}
                  {selectedCountry !== null && (
                    <Grid item md={12} sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <SelectField
                                disabled={selectedCountry ? true : false}
                                register={register("state", { onChange: (e) => callApiForCities(e) })}
                                selectType={"state"}
                                data={province}
                                value={selectedState !== null ? selectedState?._id : ""}
                                setValue={setSelectedState}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Provider city */}
                  {selectedState !== null && (
                    <Grid item md={12} sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <SelectField
                                // disabled={selectedCountry ? true : false}
                                register={register("city", { onChange: (e) => getAllCities(e.target.country_id) })}
                                selectType={"city"}
                                data={city}
                                value={selectedCity !== null ? selectedCity?._id : ""}
                                setValue={setSelectedCity}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Provider (If other please specify) */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"If Other Please Specify"} register={register("other")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider current role */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Your Current Role/Designation"} register={register("currentRole")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider experience */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Years or Experience"} register={register("experience")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provier expertise */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Your Area of Expertise (Strength) upto 5"} register={register("expertise")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider social links */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Social Links"} register={register("socialLinks")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider weblinks */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Web Link"} register={register("webLink")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider portfolio download */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Portfolio Download Link"} register={register("downloadLink")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Provider questions */}
                  {providerQuestions.map((ques, ind) => (
                    <Grid key={ind} item md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item md={6}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <FormControl>
                                <FormLabel>
                                  <Typography
                                    sx={{
                                      color: colors.white,
                                    }}
                                  >
                                    {ques.que}
                                  </Typography>
                                </FormLabel>
                                <RadioGroup row>
                                  <FormControlLabel
                                    value={ques.opt1}
                                    control={
                                      <Radio
                                        sx={{
                                          color: colors.white,
                                          "&.Mui-checked": {
                                            color: colors.white,
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        sx={{
                                          color: colors.white,
                                        }}
                                      >
                                        {ques.opt1}
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value={ques.opt2}
                                    control={
                                      <Radio
                                        sx={{
                                          color: colors.white,
                                          "&.Mui-checked": {
                                            color: colors.white,
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        sx={{
                                          color: colors.white,
                                        }}
                                      >
                                        {ques.opt2}
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item md={6}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: colors.white,
                                    "&.Mui-checked": {
                                      color: colors.white,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    color: colors.white,
                                  }}
                                >
                                  I Accept Terms of Use and Privacy Policy
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )}

              {/* Service provider end */}

              {/* Service seeker start */}

              {selected == "Seeker" && (
                <Fragment>
                  {/* Service seeker full name */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Tell Us Your Full Name (Required)"}
                              register={register("fullName", {
                                required: "Please Tell Us Your Full Name",
                              })}
                              error={errors?.fullName && true}
                              helperText={errors?.fullName?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Service seeker email */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Email Address (Required)"}
                              register={register("email", {
                                required: "Please Tell Us Your Email Address",
                              })}
                              error={errors?.email && true}
                              helperText={errors?.email?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Service seeker password */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Password (Required)"}
                              register={register("password", {
                                required: "Please Tell Us Your Password",
                              })}
                              error={errors?.password && true}
                              helperText={errors?.password?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Service seeker phone number */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField
                              placeholder={"Your Phone Number (Required)"}
                              register={register("phone", {
                                required: "Please Tell Us Your Phone Number",
                              })}
                              error={errors?.phone && true}
                              helperText={errors?.phone?.message}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Service seeker industry */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <SelectField selectType={selected} data={industries} value={selectedIndustry !== null ? selectedIndustry?._id : ""} setValue={setSelectedIndustry} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Seeker country */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <SelectField
                              register={register("country", { onChange: (e) => callApi(e) })}
                              selectType={"country"}
                              data={country}
                              value={selectedCountry !== null ? selectedCountry?._id : ""}
                              setValue={setSelectedCountry}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Seeker state */}
                  {selectedCountry !== null && (
                    <Grid item md={12} sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <SelectField
                                disabled={selectedCountry ? true : false}
                                register={register("state", { onChange: (e) => callApiForCities(e) })}
                                selectType={"state"}
                                data={province}
                                value={selectedState !== null ? selectedState?._id : ""}
                                setValue={setSelectedState}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Seeker city */}
                  {selectedState !== null && (
                    <Grid item md={12} sm={6} xs={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <SelectField
                                // disabled={selectedCountry ? true : false}
                                register={register("city", { onChange: (e) => getAllCities(e.target.country_id) })}
                                selectType={"city"}
                                data={city}
                                value={selectedCity !== null ? selectedCity?._id : ""}
                                setValue={setSelectedCity}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Service seeker role */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Your Current Role/Designation"} register={register("currentRole")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Service seeker experience */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Years or Experience"} register={register("experience")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Service seeker area of expertise */}
                  <Grid item md={12} sm={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <InputField placeholder={"Your Area of Expertise (Strength) upto 5"} register={register("expertise")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {seekerQuestions.map((ques, ind) => (
                    <Grid key={ind} item md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item md={6}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <FormControl>
                                <FormLabel>
                                  <Typography
                                    sx={{
                                      color: colors.white,
                                    }}
                                  >
                                    {ques.que}
                                  </Typography>
                                </FormLabel>
                                <RadioGroup row>
                                  <FormControlLabel
                                    value={ques.opt1}
                                    control={
                                      <Radio
                                        sx={{
                                          color: colors.white,
                                          "&.Mui-checked": {
                                            color: colors.white,
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        sx={{
                                          color: colors.white,
                                        }}
                                      >
                                        {ques.opt1}
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value={ques.opt2}
                                    control={
                                      <Radio
                                        sx={{
                                          color: colors.white,
                                          "&.Mui-checked": {
                                            color: colors.white,
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        sx={{
                                          color: colors.white,
                                        }}
                                      >
                                        {ques.opt2}
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item md={6}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: colors.white,
                                    "&.Mui-checked": {
                                      color: colors.white,
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    color: colors.white,
                                  }}
                                >
                                  I Accept Terms of Use and Privacy PolicyLabel
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
              {/* Service seeker end */}
              <Grid item md={12} sm={12} xs={12}>
                <Grid container>
                  <Grid item md={6} sm={12} xs={12}>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="outlined"
                          sx={{
                            borderColor: colors.primaryColor,
                            textTransform: "capitalize",
                            color: colors.white,
                            ":hover": {
                              borderColor: colors.primaryColor,
                            },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>

                      {/* <Grid item xs={12}>
                        <Box
                          component={"h5"}
                          sx={{
                            color: colors.white,
                          }}
                        >
                          Already have an account?
                          <Box
                            onClick={returnToTop}
                            style={{
                              color: colors.primaryColor,
                              textDecoration: "none",
                            }}
                            component={Link}
                            to={"/login"}
                          >
                            Login
                          </Box>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Signup;
