import { getCategories } from "./Category.routes";
import { get } from "../index";

const gettingCategories = {
  getCategory: async (page, limit, type) => {
    const result = await get(getCategories.category + `?page=${page}&limit=${limit}&type=${type}`);
    return result;
  },
};

export default gettingCategories;
