import React, { useState } from "react";
import { Box, Button, Container, Grid } from "@mui/material";
import * as projectImages from "../../assets/index";
import { colors } from "../../assets/colors/index";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const AboutPage = () => {
  const [title, setTitle] = useState("About us - ADSTREET");
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>
      <Box
        component={"main"}
        sx={{
          backgroundImage: `url(${projectImages.images.multiplePagesBg})`,
          width: "100%",
          height: "auto",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {/* About adstreet */}
        <Grid container paddingLeft={{ xs: "15px" }}>
          {/* about adstreet */}
          <Grid item xs={3}>
            <Box
              component={"h2"}
              sx={{
                marginTop: "80px",
                color: colors.white,
                textTransform: "uppercase",
                fontSize: { xs: "28px", md: "37px" },
                marginBottom: "20px",
              }}
            >
              About
              <Box
                component={"span"}
                sx={{
                  color: colors.primaryColor,
                  marginLeft: "15px",
                }}
              >
                adstreet
              </Box>
            </Box>
          </Grid>
          {/* welcome to adstreet */}
          <Grid item xs={11} sm={11} md={10}>
            <Box
              component={"p"}
              sx={{
                fontSize: { xs: "20px" },
                color: colors.white,
                margin: "0",
              }}
            >
              Welcome to AdStreet, the leading advertising marketplace that is revolutionizing the way brands and marketers connect, where innovation meets opportunity!
            </Box>
          </Grid>
          {/* we connect brands */}
          <Grid item xs={11} sm={11} md={10}>
            <Box
              component={"p"}
              sx={{
                fontSize: { xs: "20px" },
                color: colors.white,
                margin: "25px 0",
              }}
            >
              We connect brands, service providers and marketers in one dynamic marketplace. With our unrivaled expertise, we empower businesses to unleash their full potential.
            </Box>
          </Grid>
          {/* whether you are */}
          <Grid item xs={11} sm={11} md={10}>
            <Box
              component={"p"}
              sx={{
                fontSize: { xs: "20px" },
                color: colors.white,
                margin: "0",
              }}
            >
              Whether you're a disruptor looking to make waves, a passionate marketer seeking growth or a brand ready to make a lasting impact, AdStreet is your gateway to success.
            </Box>
          </Grid>
          {/* join us */}
          <Grid item xs={11} sm={11} md={10}>
            <Box
              component={"p"}
              sx={{
                fontSize: { xs: "20px" },
                color: colors.white,
                margin: "25px 0",
              }}
            >
              Join us and revolutionize the way you navigate the digital marketing landscape.
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => navigate("/signup")}
              sx={{
                border: `2px solid ${colors.primaryColor}`,
                color: colors.white,
                fontWeight: "bold",
                backgroundColor: colors.primaryColor,
                width: "155px",
                fontSize: "19px",
                borderRadius: "10px",
              }}
            >
              Join Now
            </Button>
          </Grid>
        </Grid>
        {/* Meet the founder */}
        <Box
          component={"h2"}
          sx={{
            fontSize: { xs: "28px", md: "37px" },
            color: colors.white,
            textTransform: "uppercase",
            mt: { xs: 7 },
            paddingLeft: { xs: "15px" },
          }}
        >
          Meet the founder
        </Box>
        <Grid container paddingLeft={{ xs: "15px" }} flexDirection={{ xs: "column-reverse", md: "row" }}>
          <Grid item xs={12} md={1.65} sx={{}}>
            <Box
              component={"img"}
              src={projectImages.images.meetTheFounder}
              sx={{
                display: "block",
                margin: { xs: "0 auto !important", md: "0" },
                pb: { xs: 4, md: "0" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              {/* Meet Syed Saad */}
              <Grid item>
                <Box
                  component={"p"}
                  sx={{
                    fontSize: "20px",
                    color: colors.white,
                    margin: "0",
                  }}
                >
                  Meet Syed Saad Hashmi, the Founder and CEO of AdStreet, on a mission to revolutionize Pakistani marketing.
                </Box>
              </Grid>
              {/* With a prestigious */}
              <Grid item>
                <Box
                  component={"p"}
                  sx={{
                    fontSize: "20px",
                    color: colors.white,
                  }}
                >
                  With a prestigious award winning background and international recognition, Saad is an industry expert, trusted by clients and agencies alike.
                </Box>
              </Grid>
              {/* He is dedicated */}
              <Grid item>
                <Box
                  component={"p"}
                  sx={{
                    fontSize: "20px",
                    color: colors.white,
                    margin: 0,
                  }}
                >
                  He is dedicated to fostering growth, collaboration and innovation within the marketing community, aiming to shape the future of Pakistan's marketing and Advertising landscape.
                </Box>
              </Grid>
              {/* Join Adstreet */}
              <Grid item>
                <Box
                  component={"p"}
                  sx={{
                    fontSize: "20px",
                    color: colors.white,
                    pb: { xs: 4 },
                  }}
                >
                  Join AdStreet and unlock unparalleled opportunities for your business today.
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AboutPage;
