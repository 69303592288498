import { Box } from "@mui/material";
import React from "react";
import { colors } from "../../assets/colors/index";

const PolicyAndTerms = ({ title, paragraph, text, list }) => {
  const para = (
    <Box
      component={"p"}
      sx={{
        color: colors.privacyAndPolicyColor,
      }}
    >
      {paragraph}
    </Box>
  );

  return (
    <>
      {/* Title */}
      <Box
        component={"h4"}
        sx={{
          color: colors.privacyAndPolicyColor,
        }}
      >
        {title}
      </Box>

      {paragraph ? (
        para
      ) : (
        <>
          {text && (
            <Box
              component={"p"}
              sx={{
                color: colors.privacyAndPolicyColor,
              }}
            >
              {text}
            </Box>
          )}
          {list && (
            <Box
              component={"ul"}
              sx={{
                color: colors.privacyAndPolicyColor,
                lineHeight: { lg: 0 },
              }}
            >
              <Box component={"li"}>{list}</Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default PolicyAndTerms;
